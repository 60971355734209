import { Card, Col, Row, Typography } from "antd";
import React from "react";
import ShareLinkOptions from "./ShareLinkOptions";

const { Text } = Typography;

const ReferralShareOptions: React.FC<{
  companyName: string;
  referralLinkId: string;
  text: string;
  fadeInOffset: number;
  companyId: string;
}> = ({ text, fadeInOffset = 0, referralLinkId, companyName, companyId }) => {
  return (
    <Row
      justify="center"
      className={`appear-from-top-${fadeInOffset}`}
    >
      <Col span={24}>
        <Card>
          <Row
            justify="center"
            style={{ marginBottom: "20px" }}
          >
            <Col>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: 200,
                }}
              >
                {text}
              </Text>
            </Col>
          </Row>
          <ShareLinkOptions
            referralLinkId={referralLinkId}
            companyName={companyName}
            companyId={companyId}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default ReferralShareOptions;
