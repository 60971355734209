import { CopyrightOutlined } from "@ant-design/icons";
import { Layout, Typography } from "antd";
import { CompanyBranding } from "layout/referralForm/ReferralForm";
import React, { useEffect, useState } from "react";

const { Footer } = Layout;
const { Text } = Typography;

const ReferralFormFooter: React.FC<{ companyBranding?: CompanyBranding }> = ({
  companyBranding,
}) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Clean up on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Footer
      style={{
        minHeight: "8vh",
        backgroundColor: "white",
        display: "flex",
        flexDirection: isMobile ? "column-reverse" : "row",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        height: isMobile ? "100px" : "initial",
      }}
    >
      <Text
        style={{
          fontWeight: 200,
          fontSize: "12px",
          justifySelf: "flex-start",
        }}
      >
        <CopyrightOutlined /> ReferPro 2024
      </Text>
      <Text
        style={{
          fontWeight: 200,
          fontSize: "12px",
          justifySelf: "center",
        }}
      >
        By interacting with this referral form, you are abiding to our{" "}
        <a
          href={
            companyBranding.termsAndConditionsUrl
              ? companyBranding.termsAndConditionsUrl
              : "https://www.getrefer.pro/terms-and-conditions"
          }
        >
          Terms and Conditions
        </a>
      </Text>
    </Footer>
  );
};

export default ReferralFormFooter;
