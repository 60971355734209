import { Form, Typography } from "antd";
import React, { useEffect, useState } from "react";

import NameInput from "./basicComponents/NameInput";
import PhoneNumberInput from "./basicComponents/PhoneNumberInput";

const { Text } = Typography;

const ReferredPersonInput: React.FC<{
  companyName: string;
  disabled?: boolean;
  setIsValid: (valid: Boolean) => unknown;
  setValues: (values: { name: string; phoneNumber: string }) => unknown;
  fadeInOffset?: number;
}> = ({ companyName, disabled, setIsValid, fadeInOffset = 0, setValues }) => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [nameIsValid, setNameIsValid] = useState(false);
  const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(false);

  useEffect(() => {
    // for communication with parent component
    setIsValid(nameIsValid && phoneNumberIsValid);
    setValues({ name, phoneNumber });
  }, [nameIsValid, phoneNumberIsValid]);

  return (
    <>
      <Form.Item
        className={`appear-from-top-${0 + fadeInOffset}`}
        name="referralName"
        label={
          <Text
            style={{
              fontWeight: 200,
              fontSize: "20px",
            }}
          >
            Who would you like to refer to{" "}
            <Text
              style={{
                fontWeight: 400,
                fontSize: "20px",
              }}
            >
              {companyName}
            </Text>
            ?
          </Text>
        }
      >
        <NameInput
          disabled={disabled}
          onChange={setName}
          setIsValid={setNameIsValid}
          placeholder="Enter their name"
        />
      </Form.Item>
      <Form.Item
        className={`appear-from-top-${1 + fadeInOffset}`}
        name="referralPhoneNumber"
        label={
          <Text
            style={{
              fontWeight: 200,
              fontSize: "20px",
            }}
          >
            What's their phone number?
          </Text>
        }
      >
        <PhoneNumberInput
          disabled={!nameIsValid || disabled}
          onChange={setPhoneNumber}
          placeholder="Enter their phone number"
          setIsValid={setPhoneNumberIsValid}
        />
      </Form.Item>
    </>
  );
};

export default ReferredPersonInput;
