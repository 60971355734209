import {
  FacebookOutlined,
  InstagramOutlined,
  LinkOutlined,
  MessageOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Button, Col, Input, Modal, Row, Space, Typography } from "antd";
import NextDoorOutlined from "assets/icons/NextDoorOutlined";
import { CREATE_SHAREABLE_LINK } from "data/publicQueries";
import PosthogHandler from "lib/posthog";
import React, { useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { COMPANY_IDS } from "../../constants";

const { Text } = Typography;

const ShareLinkOptions: React.FC<{
  referralLinkId: string;
  companyName: string;
  companyId: string;
}> = ({ referralLinkId, companyName, companyId }) => {
  const [contentToCopy, setContentToCopy] = useState<string | null>(null);
  const inputRef = useRef(null);

  const [buttonSize, setButtonSize] = useState("50px");
  useEffect(() => {
    const adjustButtonSize = () => {
      if (window.innerWidth < 420) {
        setButtonSize("40px");
      } else {
        setButtonSize("50px");
      }
    };
    adjustButtonSize();
    window.addEventListener("resize", adjustButtonSize);
  }, []);

  const [
    socialMediaInstructionModalVisible,
    setSocialMediaInstructionModalVisible,
  ] = useState(false);

  const [
    socialMediaInstructionModelTitle,
    setSocialMediaInstructionModelTitle,
  ] = useState("");

  const [
    socialMediaInstructionModalContent,
    setSocialMediaInstructionModalContent,
  ] = useState(<></>);

  const [
    socialMediaInstructionConfirmAction,
    setSocialMediaInstructionConfirmAction,
  ] = useState(() => async () => {});

  const instagramInstructionContent = (
    <Space direction="vertical">
      <Text>We're about to open Instagram!</Text>
      <Text>
        We've copied an example post with your custom referral link to your
        clipboard. Paste it into an Instagram post and make it your own!
      </Text>
      <Text>
        Click OK to continue to Instagram, or Cancel to stay on this page.
      </Text>
    </Space>
  );
  const instagramInstructionTitle = "Gather referrals through Instagram!";
  const instagramUrl = "https://www.instagram.com/";
  const instagramConfirmAction = async () => {
    PosthogHandler.capture("instagram_share_confirmed", {
      linkTarget: window.location.href,
    });
    window.location.href = instagramUrl;
  };

  const nextdoorInstructionContent = (
    <Space direction="vertical">
      <Text>We're about to open Nextdoor!</Text>
      <Text>
        We've copied an example post with your custom referral link to your
        clipboard. Paste it into a Nextdoor post and make it your own!
      </Text>
      <Text>
        Click OK to continue to Nextdoor, or Cancel to stay on this page.
      </Text>
    </Space>
  );

  const nextdoorInstructionTitle = "Gather referrals through Nextdoor!";
  const nextdoorUrl = "https://nextdoor.com/news_feed/";
  const nextdoorConfirmAction = async () => {
    PosthogHandler.capture("nextdoor_share_confirmed", {
      linkTarget: window.location.href,
    });
    window.location.href = nextdoorUrl;
  };

  const linkInstructionContent = (
    <Space direction="vertical">
      <Text>
        Click OK to copy your sharable referral link to your clipboard.
      </Text>
      <Text>
        You can send it to anyone you want, and they can use it to refer
        themselves so you get credit. You can also post it on social media or in
        a text message. (You can also just use one of the buttons above!)
      </Text>
    </Space>
  );
  const linkInstructionTitle =
    "Gather referrals by sharing your personal referral link!";

  const linkConfirmAction = async () => {
    PosthogHandler.capture("shareable_link_share_confirmed", {
      linkTarget: window.location.href,
    });
    setSocialMediaInstructionModalVisible(false);
  };

  const [readyToShare, setReadyToShare] = useState(false);

  const [createShareableLink] = useMutation(CREATE_SHAREABLE_LINK);

  const shareableLink = async () => {
    const shareableLink = await createShareableLink({
      variables: {
        input: {
          referralLinkId,
        },
      },
    });
    return shareableLink.data?.createShareableLink.link;
  };

  const handleOpeniMessage = async () => {
    PosthogHandler.capture("text_message_share_clicked", {
      linkTarget: window.location.href,
    });

    const linkTarget = await shareableLink();
    const recipient = "phone_number"; // Replace with the recipient's phone number
    const message = `Hey! Here is my referral link for ${companyName}: ${linkTarget}`;
    const encodedMessage = encodeURIComponent(message);

    window.location.href = `sms:${recipient}&body=${encodedMessage}`;
  };

  const handleOpenFacebook = async () => {
    PosthogHandler.capture("facebook_share_clicked", {
      linkTarget: window.location.href,
    });
    const linkTarget = await shareableLink();
    window.location.href = `https://www.facebook.com/sharer/sharer.php?u=${linkTarget}`;
  };

  const handleOpenTwitter = async () => {
    PosthogHandler.capture("twitter_share_clicked", {
      linkTarget: window.location.href,
    });

    const linkTarget = await shareableLink();
    const tweetText = encodeURIComponent(
      `I had a GREAT experience with ${companyName}! Request service through ${linkTarget} so you can have a great experience too! (plus, you'll get a discount!)`
    );
    window.location.href = `https://twitter.com/intent/tweet?text=${tweetText}`;
  };

  const handleOpenInstagram = async () => {
    PosthogHandler.capture("instagram_share_clicked", {
      linkTarget: window.location.href,
    });
    setSocialMediaInstructionConfirmAction(() => async () => {});
    setSocialMediaInstructionModalContent(instagramInstructionContent);
    setSocialMediaInstructionModelTitle(instagramInstructionTitle);
    setSocialMediaInstructionModalVisible(true);

    const linkTarget = await shareableLink();
    setContentToCopy(
      `I had a GREAT experience with ${companyName}! Request service through ${linkTarget} so you can have a great experience too! (plus, you'll get a discount!)`
    );
    setSocialMediaInstructionConfirmAction(() => instagramConfirmAction);
    setReadyToShare(true);
  };

  const handleOpenNextDoor = async () => {
    PosthogHandler.capture("nextdoor_share_clicked", {
      linkTarget: window.location.href,
    });
    setSocialMediaInstructionConfirmAction(() => async () => {});
    setSocialMediaInstructionModalContent(nextdoorInstructionContent);
    setSocialMediaInstructionModelTitle(nextdoorInstructionTitle);
    setSocialMediaInstructionModalVisible(true);

    const linkTarget = await shareableLink();
    setContentToCopy(
      `I had a GREAT experience with ${companyName}! Request service through ${linkTarget} so you can have a great experience too! (plus, you'll get a discount!)`
    );

    setSocialMediaInstructionConfirmAction(() => nextdoorConfirmAction);
    setReadyToShare(true);
  };

  const handleOpenLink = async () => {
    PosthogHandler.capture("shareable_link_clicked", {
      linkTarget: window.location.href,
    });
    setSocialMediaInstructionConfirmAction(() => async () => {});
    setSocialMediaInstructionModalContent(linkInstructionContent);
    setSocialMediaInstructionModelTitle(linkInstructionTitle);
    setSocialMediaInstructionModalVisible(true);

    const linkTarget = await shareableLink();
    setContentToCopy(linkTarget);
    setSocialMediaInstructionConfirmAction(() => linkConfirmAction);
    setReadyToShare(true);
  };

  return (
    <Space
      direction="vertical"
      size="large"
    >
      <Modal
        open={socialMediaInstructionModalVisible}
        title={socialMediaInstructionModelTitle}
        onCancel={() => setSocialMediaInstructionModalVisible(false)}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setContentToCopy(null);
              setSocialMediaInstructionModalVisible(false);
            }}
          >
            Cancel
          </Button>,
          <CopyToClipboard text={contentToCopy}>
            <Button
              disabled={!readyToShare}
              key="ok"
              type="primary"
              onClick={socialMediaInstructionConfirmAction}
            >
              OK
            </Button>
          </CopyToClipboard>,
        ]}
      >
        {socialMediaInstructionModalContent}
      </Modal>
      <Row
        justify="center"
        gutter={[8, 8]}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Col span={companyId === COMPANY_IDS.THE_BRIGHTWOOD ? 24 : undefined}>
          <Button
            size="large"
            style={{
              cursor: "pointer",
              boxShadow: "rgba(0, 0, 0, 0.2) 3px 5px 10px",
              height: buttonSize,
              width:
                companyId === COMPANY_IDS.THE_BRIGHTWOOD ? "100%" : buttonSize,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={handleOpeniMessage}
          >
            <Space direction="horizontal">
              <Col style={{ fontSize: 20 }}>
                <Input style={{ display: "none" }} />
                <MessageOutlined />
              </Col>
              {companyId === COMPANY_IDS.THE_BRIGHTWOOD ? (
                <Col
                  style={{
                    height: "30px",
                    lineHeight: "30px",
                    fontWeight: 200,
                    fontSize: "20px",
                  }}
                >
                  Share via Text
                </Col>
              ) : null}
            </Space>
          </Button>
        </Col>
        {companyId === COMPANY_IDS.THE_BRIGHTWOOD ? null : (
          <>
            <Col>
              <Button
                size="large"
                style={{
                  cursor: "pointer",
                  boxShadow: "rgba(0, 0, 0, 0.2) 3px 5px 10px",
                  height: buttonSize,
                  width: buttonSize,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={handleOpenFacebook}
              >
                <Space direction="horizontal">
                  <Col style={{ fontSize: 20 }}>
                    <Input style={{ display: "none" }} />
                    <FacebookOutlined />
                  </Col>
                </Space>
              </Button>
            </Col>
            <Col>
              <Button
                size="large"
                style={{
                  cursor: "pointer",
                  boxShadow: "rgba(0, 0, 0, 0.2) 3px 5px 10px",
                  height: buttonSize,
                  width: buttonSize,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={handleOpenTwitter}
              >
                <Space direction="horizontal">
                  <Col style={{ fontSize: 20 }}>
                    <Input style={{ display: "none" }} />
                    <TwitterOutlined />
                  </Col>
                </Space>
              </Button>
            </Col>
            <Col>
              <Button
                size="large"
                style={{
                  cursor: "pointer",
                  boxShadow: "rgba(0, 0, 0, 0.2) 3px 5px 10px",
                  height: buttonSize,
                  width: buttonSize,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={handleOpenInstagram}
              >
                <Space direction="horizontal">
                  <Col style={{ fontSize: 20 }}>
                    <Input style={{ display: "none" }} />
                    <InstagramOutlined />
                  </Col>
                </Space>
              </Button>
            </Col>
            <Col>
              <Button
                size="large"
                style={{
                  cursor: "pointer",
                  boxShadow: "rgba(0, 0, 0, 0.2) 3px 5px 10px",
                  height: buttonSize,
                  width: buttonSize,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={handleOpenNextDoor}
              >
                <Space direction="horizontal">
                  <Col style={{ fontSize: 20 }}>
                    <Input style={{ display: "none" }} />
                    <NextDoorOutlined />
                  </Col>
                </Space>
              </Button>
            </Col>
          </>
        )}
      </Row>
      <Row justify="center">
        <Button
          size="large"
          style={{
            height: "50px",
            cursor: "pointer",
            boxShadow: "rgba(0, 0, 0, 0.2) 3px 5px 10px",
            paddingLeft: 25,
            paddingRight: 25,
            width: "100%",
          }}
          onClick={handleOpenLink}
        >
          <Space direction="horizontal">
            <Col style={{ fontSize: 20 }}>
              <Input
                style={{ display: "none" }}
                ref={inputRef}
              />
              <LinkOutlined />
            </Col>
            <Col
              style={{
                height: "30px",
                lineHeight: "30px",
                fontWeight: 200,
                fontSize: "20px",
              }}
            >
              Copy Link to Share
            </Col>
          </Space>
        </Button>
      </Row>
    </Space>
  );
};

export default ShareLinkOptions;
