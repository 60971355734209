import { ReferralType } from "__generated__/graphql";
import { Col, Divider, Form, Row } from "antd";
import CompanyImage from "components/referralForm/basicComponents/CompanyImage";
import CompanyVideo from "components/referralForm/basicComponents/CompanyVideo";
import ReferralFormTitle from "components/referralForm/basicComponents/ReferralFormTitle";
import ReferredByPersonInput from "components/referralForm/ReferredByPersonInput";
import ReferredPersonInput from "components/referralForm/ReferredPersonInput";
import ReferralSubmitButtons from "components/referralForm/SubmitReferral";
import ReferralFormLayout, {
  CompanyBranding,
} from "layout/referralForm/ReferralForm";
import PosthogHandler from "lib/posthog";
import { COMPANY_IDS } from "../../../constants";
// eslint-disable-next-line import/no-named-as-default
import React from "react";

const EmployeeReferralForm: React.FC<{
  companyId: string;
  companyName: string;
  employeeName?: string;
  saveEmployeeReferral: (
    referredByName: string,
    referredByPhone: string,
    referredByEmail: string,
    referralName: string,
    referralPhone: string,
    referralType: ReferralType
  ) => any;
  referralBannerMessage?: string;
  isEmbedded?: boolean;
  companyBranding?: CompanyBranding;
}> = ({
  companyId,
  companyName,
  employeeName,
  saveEmployeeReferral,
  referralBannerMessage,
  isEmbedded = false,
  companyBranding = {},
}) => {
  const [referredByName, setReferredByName] = React.useState("");
  const [referredByPhoneNumber, setReferredByPhoneNumber] = React.useState("");
  const [referredByEmail, setReferredByEmail] = React.useState("");
  const [referralName, setReferralName] = React.useState("");
  const [referralPhoneNumber, setReferralPhoneNumber] = React.useState("");

  const [referredByValid, setReferredByValid] = React.useState(false);
  const [referredValid, setReferredValid] = React.useState(false);

  if (companyBranding.alternateCompanyName) {
    companyName = companyBranding.alternateCompanyName;
  }

  return (
    <ReferralFormLayout
      companyId={companyId}
      referralBannerMessage={
        PosthogHandler.isFeatureFlagEnabledForVariant(
          "reward-as-header",
          "test"
        )
          ? undefined
          : referralBannerMessage
      }
      isEmbedded={isEmbedded}
      companyBranding={companyBranding}
    >
      <ReferralFormTitle
        title={
          companyId === COMPANY_IDS.THE_BRIGHTWOOD
            ? `Thanks for using ${employeeName}'s link`
            : PosthogHandler.isFeatureFlagEnabledForVariant(
                  "reward-as-header",
                  "test"
                )
              ? referralBannerMessage
              : `Hi! Thanks for using ${employeeName}'s link!`
        }
        wavingHand={true}
        emoji={
          companyId === COMPANY_IDS.THE_BRIGHTWOOD
            ? ""
            : PosthogHandler.isFeatureFlagEnabledForVariant(
                  "reward-as-header",
                  "test"
                )
              ? "🎉"
              : "👋"
        }
      />
      <Divider />
      <CompanyVideo videoId={companyBranding.youtubeVideoId} />
      <CompanyImage companyId={companyId} />
      <Row justify="center">
        <Col>
          <Form
            name="materialForm"
            layout="vertical"
          >
            <ReferredByPersonInput
              setIsValid={(valid: boolean) => {
                setReferredByValid(valid);
              }}
              setValues={(values: {
                name: string;
                phoneNumber: string;
                email: string;
              }) => {
                PosthogHandler.capture(
                  "employee_referral_form_referred_by_person_input",
                  {
                    linkTarget: window.location.href,
                    companyId,
                    referredByName: values.name,
                    referredByPhoneNumber: values.phoneNumber,
                    referredByEmail: values.email,
                  }
                );
                setReferredByName(values.name);
                setReferredByPhoneNumber(values.phoneNumber);
                setReferredByEmail(values.email);
              }}
              fadeInOffset={1}
            />
            <ReferredPersonInput
              companyName={companyName}
              setIsValid={(valid: boolean) => {
                setReferredValid(valid);
              }}
              setValues={(values: { name: string; phoneNumber: string }) => {
                PosthogHandler.capture(
                  "employee_referral_form_referred_person_input",
                  {
                    linkTarget: window.location.href,
                    companyId,
                    referralName: values.name,
                    referralPhoneNumber: values.phoneNumber,
                  }
                );
                setReferralName(values.name);
                setReferralPhoneNumber(values.phoneNumber);
              }}
              fadeInOffset={4}
              disabled={!referredByValid}
            />
            <ReferralSubmitButtons
              submitReferral={async () => {
                PosthogHandler.capture(
                  "employee_referral_form_submit_referral",
                  {
                    linkTarget: window.location.href,
                    companyId,
                    referredByName,
                    referredByPhoneNumber,
                    referredByEmail,
                    referralName,
                    referralPhoneNumber,
                  }
                );
                await saveEmployeeReferral(
                  referredByName,
                  referredByPhoneNumber,
                  referredByEmail,
                  referralName,
                  referralPhoneNumber,
                  ReferralType.Standard
                );
              }}
              submitSelfReferral={async () => {
                PosthogHandler.capture(
                  "employee_referral_form_submit_self_referral",
                  {
                    linkTarget: window.location.href,
                    companyId,
                    referredByName,
                    referredByPhoneNumber,
                    referredByEmail,
                  }
                );
                await saveEmployeeReferral(
                  referredByName,
                  referredByPhoneNumber,
                  referredByEmail,
                  referredByName,
                  referredByPhoneNumber,
                  ReferralType.Self
                );
              }}
              isReadyToSubmitReferral={referredByValid && referredValid}
              isReadyToSubmitSelfReferral={
                referredByValid && !referralName && !referralPhoneNumber
              }
              fadeInOffset={6}
            />
          </Form>
        </Col>
      </Row>
    </ReferralFormLayout>
  );
};

export default EmployeeReferralForm;
