import { ReferralType } from "__generated__/graphql";
import { Col, Divider, Form, Row } from "antd";
import CompanyImage from "components/referralForm/basicComponents/CompanyImage";
import CompanyVideo from "components/referralForm/basicComponents/CompanyVideo";
import ReferralFormTitle from "components/referralForm/basicComponents/ReferralFormTitle";
import ReferredPersonInput from "components/referralForm/ReferredPersonInput";
import ReferralSubmitButtons from "components/referralForm/SubmitReferral";

import ReferralShareOptions from "components/referralForm/ReferralShareOptions";
import ReferralFormLayout, {
  CompanyBranding,
} from "layout/referralForm/ReferralForm";
import PosthogHandler from "lib/posthog";
import React, { useState } from "react";
import { COMPANY_IDS } from "../../../constants";

const CustomerReferralForm: React.FC<{
  companyId: string;
  companyName: string;
  firstName: string;
  saveCustomerReferral: (
    name: string,
    phone: string,
    referralType: ReferralType
  ) => Promise<boolean>;
  referralLinkId: string;
  rewardAmount: number;
  referralBannerMessage?: string;
  companyBranding?: CompanyBranding;
}> = ({
  companyId,
  companyName,
  firstName,
  referralBannerMessage,
  referralLinkId,
  saveCustomerReferral,
  companyBranding = {},
}) => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [readyToSubmit, setReadyToSubmit] = useState(false);

  async function saveReferral(type: ReferralType) {
    await saveCustomerReferral(name, phoneNumber, type);
  }

  if (companyBranding.alternateCompanyName) {
    companyName = companyBranding.alternateCompanyName;
  }

  return (
    <ReferralFormLayout
      companyId={companyId}
      referralBannerMessage={
        PosthogHandler.isFeatureFlagEnabledForVariant(
          "reward-as-header",
          "test"
        )
          ? undefined
          : referralBannerMessage
      }
      companyBranding={companyBranding}
    >
      <ReferralFormTitle
        title={
          PosthogHandler.isFeatureFlagEnabledForVariant(
            "reward-as-header",
            "test"
          )
            ? referralBannerMessage
            : `Hi ${firstName},`
        }
        wavingHand={true}
        emoji={
          companyId === COMPANY_IDS.THE_BRIGHTWOOD
            ? "" // they don't want an emoji
            : PosthogHandler.isFeatureFlagEnabledForVariant(
                  "reward-as-header",
                  "test"
                )
              ? "🎉"
              : "👋"
        }
      />
      <CompanyVideo videoId={companyBranding.youtubeVideoId} />
      <CompanyImage companyId={companyId} />
      <Row justify="center">
        <Col>
          <Form
            name="materialForm"
            layout="vertical"
          >
            <ReferredPersonInput
              companyName={companyName}
              setIsValid={(valid: boolean) => {
                setReadyToSubmit(valid);
              }}
              setValues={(values: { name: string; phoneNumber: string }) => {
                PosthogHandler.capture(
                  "customer_referral_form_referred_person_input",
                  {
                    linkTarget: window.location.href,
                    companyId,
                    name: values.name,
                    phoneNumber: values.phoneNumber,
                  }
                );
                setName(values.name);
                setPhoneNumber(values.phoneNumber);
              }}
              fadeInOffset={1}
            />
            <ReferralSubmitButtons
              submitReferral={async () => {
                PosthogHandler.capture(
                  "customer_referral_form_submit_referral",
                  {
                    linkTarget: window.location.href,
                    companyId,
                    name,
                    phoneNumber,
                  }
                );
                return await saveReferral(ReferralType.Standard);
              }}
              submitSelfReferral={async () => {
                PosthogHandler.capture(
                  "customer_referral_form_submit_self_referral",
                  {
                    linkTarget: window.location.href,
                    companyId,
                    name,
                    phoneNumber,
                  }
                );
                return await saveReferral(ReferralType.Self);
              }}
              isReadyToSubmitReferral={readyToSubmit}
              isReadyToSubmitSelfReferral={!readyToSubmit}
              fadeInOffset={3}
            />
          </Form>
          <Divider />
          <ReferralShareOptions
            text={"Or invite others to refer themselves by sharing this link:"}
            fadeInOffset={4}
            companyName={companyName}
            referralLinkId={referralLinkId}
            companyId={companyId}
          />
        </Col>
      </Row>
    </ReferralFormLayout>
  );
};

export default CustomerReferralForm;
